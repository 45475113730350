.tentang-page {
  .heading {
    margin: 5rem 0 0 0;
    background: #44004e;
    color: white;
    img {
      margin-top: 4rem;
      margin-bottom: -1px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
      .row {
        .col-img {
          order: 2;
        }
        .col-text {
          order: 1;
        }
      }
    }
  }
  .intro {
    margin: 4rem 0;
  }
  .embed {
    margin-bottom: 6rem;
  }
  .lists {
    margin-bottom: 6rem;
  }

  .tentang-container {
    .row:nth-child(odd) {
      .col-text {
        order: 1;
      }
      .col-img {
        order: 2;
      }
    }
    .row:nth-child(even) {
      .col-text {
        order: 2;
      }
      .col-img {
        order: 1;
      }
    }
  }
}
