.lepoapp-page {
  .hero {
    margin-top: 5.5rem;
    padding: 3rem 0;
    margin-bottom: 3rem;
    .text-heading {
      line-height: 50px;
    }
    .des {
      font-weight: 300;
    }
  }
  .feature {
    margin-top: 5rem;
    .lists {
      margin-bottom: 6rem;
    }
  }
  .feature-container {
    .row:nth-child(odd) {
      .col-text {
        order: 1;
      }
      .col-img {
        order: 2;
      }
    }
    .row:nth-child(even) {
      .col-text {
        order: 2;
      }
      .col-img {
        order: 1;
      }
    }
  }
}
