.carapake-page {
  .heading {
    margin-top: 4rem;
    padding: 5rem 0 5rem 0;
    color: white;
    background: url("../img/caraleft.png") no-repeat left,
      url("../img/cararight.png") no-repeat right #29012f;
  }
  .submenu {
    background: white;
    border-radius: 100px;
    margin-top: 20px;
    padding: 0.5rem 0;
    margin-bottom: 0;
    .nav-item {
      .nav-link {
        color: #29012f;
      }
    }
  }
  .pergroup:nth-child(even) {
    background: #eae6ea;
  }
  .pergroup {
    padding: 5rem 0 7rem 0;
  }
  .lists {
    .mainimg {
      margin-bottom: 20px;
    }
    .title {
      font-weight: 800;
      margin: 0 0 10px 0;
    }
  }
}
