.footer {
  background-color: #29012f;
  color: white;
  padding: 3rem 0 1rem 0;
  font-size: 1rem;
  a {
    color: white;
  }
  .head {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
  }
  .list {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    .head {
      margin-top: 1.5rem;
    }
    .head.headfirst {
      margin-top: 0;
    }
    .social-footer {
      margin-bottom: 2rem;
    }
    .nav {
      display: block;
      .nav-item {
        .nav-link {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
        }
      }
    }
    .pt-bottom {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .list {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.6rem;
  }
  .footer-bottom {
    border-top: 1px solid #460a4f;
    padding-top: 10px;
    margin-top: 30px;
  }
}
