@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap");
$font-primary: "Nunito", sans-serif;
$color-primary: #652ba4;
$theme-colors: (
  "primary": #44004e
);
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./navbar.scss";
@import "./carapake.scss";
@import "./tentang.scss";
@import "./promo.scss";
@import "./lepoapp.scss";
@import "./artikel.scss";
@import "./footer.scss";
@import "./home.scss";
body {
  font-family: $font-primary;
  font-size: 1.25rem;
  font-weight: 300;
}
.container {
  max-width: 1100px !important;
}
.text-heading {
  font-size: 40px;
  font-weight: 700;
}
.csinfo {
  background: url("../img/bgcs.png") no-repeat right #44004e;
  color: white;
  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
    .col-img {
      order: 3;
    }
    .col-text {
      order: 1;
    }
    .col-more {
      order: 2;
    }
  }
  img {
    margin-top: 3.5rem;
  }
  a {
    color: #f8ac00;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    padding: 0 2rem;
  }
}

// loading
.loading-ui {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

.slider-arrow-right {
  background: #29012f;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: 35%;
  cursor: pointer;
  img {
    margin-top: 16px;
    width: 25px;
  }
}

.slider-arrow-left {
  cursor: pointer;
  background: #29012f;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 35%;
  z-index: 40;
  img {
    margin-top: 16px;
    width: 25px;
  }
}

.syarat-page {
  .main {
    margin-top: 4rem;
    padding: 4rem 0 5rem 0;
  }
}
