.home-page {
  .hero {
    margin-top: 5.5rem;
    padding: 3rem 0;
    margin-bottom: 3rem;
    .des {
      font-weight: 300;
    }
  }
  .benefit {
    padding: 5rem 0;
    .text-heading {
      margin-bottom: 3rem;
    }
    .lists {
      img {
        margin-bottom: 1rem;
      }
    }
  }
  .promo-home {
    padding-bottom: 5rem;
    .sub-heading {
      margin-bottom: 3rem;
    }
    .lists {
      img {
        margin-bottom: 1rem;
      }
      .title {
        font-weight: 800;
      }
      .des {
        font-size: 1rem;
        margin-bottom: 5px;
      }
      .expdate {
        font-size: 0.9rem;
        color: gray;
      }
    }
    .readmore {
      padding: 0.7rem 3rem;
      margin-top: 2.5rem;
    }
  }
  .galeri {
    margin-bottom: 5rem;
  }
}
