.mynavbar {
  padding: 1.5rem 0;
  background: white;
  border-bottom: 1px solid #ddd;
  @include media-breakpoint-down(sm) {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 1, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 1, 0.15);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .nav-link {
    font-size: 0.9rem;
    color: black;
    &:hover {
      color: $color-primary;
    }
    &.active {
      color: $color-primary;
    }
  }
}

.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 3000;
  .nav-item-link {
    display: block;
    padding: 15px 20px;
    border-bottom: 1px solid #edecec;
    margin: 0 30px;
    transition: all 0.3s;
    &:hover {
      text-decoration: none;
      background: #edecec;
      color: #29012f !important;
    }
  }
}

.closebutton {
  padding: 20px 30px;
}
