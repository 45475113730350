.promo-page {
  .new {
    margin-top: 4rem;
    padding: 4rem 0 5rem 0;
    background: #e5e5e5;
  }
  .other {
    margin: 4rem 0 7rem 0;
  }
  .lists {
    img {
      margin-bottom: 1rem;
    }
    .title {
      font-weight: 800;
    }
    .des {
      font-size: 1rem;
      margin-bottom: 5px;
      img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
    .expdate {
      font-size: 0.9rem;
      color: gray;
    }
  }
}
