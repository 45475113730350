.artikel-page {
  .banner {
    margin-top: 5rem;
    margin-bottom: 4rem;
  }
  .text-heading {
    margin-bottom: 2rem;
  }
  .lists {
    .cole {
      margin-bottom: 2rem;
    }
    img {
      margin-bottom: 1rem;
    }
    .date {
      font-size: 0.8rem;
      color: gray;
    }
    .title {
      font-weight: 700;
    }
    .desc {
      margin-bottom: 1rem;
    }
    .readmore {
      color: $color-primary;
    }
  }
}

.artikeldetail-content {
  margin-top: 8rem;
  margin-bottom: 8rem;
  .breadcrumb {
    font-size: 1rem;
  }
  .date {
    font-size: 1rem;
    color: gray;
  }
  .desc {
    font-size: 1rem;
    img {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }
}
